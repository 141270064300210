import lodashMerge from 'lodash/merge';
import { ndxRpc } from "../utils/ndxRpc";

const service = 'workflowInstanceSnapshot';

export default lodashMerge({}, {
    filterBy(criteria) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'filterBy', [criteria])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    find(snapshotId) {
        return new Promise((resolve, reject) => {
            ndxRpc(service, 'find', [+snapshotId])
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
});
