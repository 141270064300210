import userApi from "../../api/user";

//state
const state = {
    user: null,
    shopClient: null,
    client: null,
    hasClientSwitch: false,
    adminUrl: null
};

// getters
const getters = {
    getNewAddress: function () {
        return {
            company: '',
            companySalutation: '',
            companySupplement: '',
            gender: 'no_gender',
            firstname: '',
            lastname: '',
            nameSupplement: '',
            position: '',
            academicTitle: '',
            titleOfNobility: '',
            street: '',
            streetExt: '',
            houseNo: '',
            houseNoExt: '',
            premise: '',
            locality: '',
            zipcode: '',
            city: '',
            state: '',
            countryId: 'DE'
        };
    },
    substitutes: function (state) {
        return state.user.substitutes;
    },
    currentUserId: function (state) {
        return state.user?.id;
    },
    currentClientId: function (state) {
        return state.client?.id;
    },
    currentShopClientId: function (state) {
        return state.shopClient?.id;
    },
    mainAddress: function (state) {
        if (state.user?.contact?.addresses?.length > 0
        ) {
            return state.user.contact.addresses[0];
        }
        return null;
    },
    userFirstname: function (state, getters) {
        const address = getters.mainAddress;
        return address ? address.firstname : null;
    },
    userFullname(state, getters) {
        const address = getters.mainAddress;
        return address
            ? (address.firstname + ' ' + address.lastname).trim()
            : '';
    },
    userEmail(state) {
        if (state.user?.contact) {
            return state.user.contact.emailAddress;
        }

        return '';
    },
    shopClientName(state) {
        return state.client?.name ?? '';
    },
    hasClientSwitch(state) {
        return state.hasClientSwitch;
    },
    clientAddresses: function (state) {
        if (state.client?.contact?.addresses?.length > 0) {
            return state.client.contact.addresses;
        }
        return [];
    },
    userAddresses: function (state) {
        if (state.user?.contact?.addresses?.length > 0) {
            return state.user.contact.addresses;
        }
        return [];
    },

    getAddress: (state, getters) => (addressId) => {
        let result = getters.userAddresses.find((address) => {
            return +address.id === +addressId;
        });

        if (result === undefined) {
            result = getters.clientAddresses.find((address) => {
                return +address.id === +addressId;
            });
        }

        return result !== undefined ? result : null;
    }
};

// actions
const actions = {
    filterBy: (ctx, data) => {
        const customParams = data.customParams || null;
        const appData = data.appData;

        return new Promise((resolve) => {
            userApi.filterBy(
                appData,
                appData.offset,
                appData.limit,
                appData.order,
                appData.ascending,
                customParams
            ).then((result) => {
                resolve(result);
            }).catch((error) => {
                resolve(error);
            });
        });
    },
    getUserAndClient({commit}) {
        return new Promise((resolve, reject) => {
            userApi.getUserAndClient()
                .then((result) => {
                    commit('SET_USER', result.user);
                    commit('SET_SHOP_CLIENT', result.shopClient);
                    commit('SET_CLIENT', result.client);
                    commit('SET_HAS_CLIENT_SWITCH', result.hasClientSwitch);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateUser({commit, state}, data) {
        const reloadAfterUpdate = 'preferredLocale' in data && data.preferredLocale !== state.user.preferredLocale;
        return new Promise((resolve, reject) => {
            userApi.update(data)
                .then((result) => {
                    if (reloadAfterUpdate) {
                        // relpoad page, because language was changed!
                        window.location.reload();
                    } else {
                        commit('SET_USER', result);
                        if ('preferredLocale' in result && result.preferredLocale.length > 0) {
                            this.dispatch('session/switchLanguage', result.preferredLocale);
                        }
                        resolve();
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateUserAddress({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            userApi.updateUserAddress(data.addressData, data.addressType)
                .then((result) => {
                    dispatch('basket/getMultiAddressDeliveryAddresses', {}, {root: true});
                    commit('SET_USER', result.data);
                    resolve(state.user.contact.addresses.find(item => item.id === result.addressId));
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteUserAddress({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            userApi.deleteUserAddress(data.addressId)
                .then((result) => {
                    dispatch('basket/getMultiAddressDeliveryAddresses', {}, {root: true});
                    commit('SET_USER', result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateUserLanguage({state}, data) {
        return new Promise((resolve, reject) => {
            userApi.update({
                id: state.user.id,
                preferredLocale: data.locale
            }).then(() => {
                // reload page after success!
                window.location.reload();
            }).catch((error) => {
                reject(error);
            });
        });
    },
    updateClient({commit}, data) {
        return new Promise((resolve, reject) => {
            userApi.updateClient(data)
                .then((result) => {
                    commit('SET_CLIENT', result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateClientAddress({commit, state, dispatch}, data) {
        return new Promise((resolve, reject) => {
            userApi.updateClientAddress(data.addressData, data.addressType)
                .then((result) => {
                    dispatch('basket/getMultiAddressDeliveryAddresses', {}, {root: true});
                    commit('SET_CLIENT', result['data']);
                    resolve(state.client.contact.addresses.find(item => item.id === result.addressId));
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    changeClientAddress({commit}, data) {
        return new Promise((resolve, reject) => {
            userApi.changeClientAddress(data.type, data.addressId)
                .then((result) => {
                    commit('SET_CLIENT', result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    deleteClientAddress({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            userApi.deleteClientAddress(data.addressId)
                .then((result) => {
                    dispatch('basket/getMultiAddressDeliveryAddresses', {}, {root: true});
                    commit('SET_CLIENT', result);
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateAddressSettings({commit}, data) {
        return new Promise((resolve, reject) => {
            userApi.updateAddressSettings(data.addressId, data.settings, data.addressParent)
                .then((result) => {
                    if (data.addressParent === 'client') {
                        commit('SET_CLIENT', result);
                    } else if (data.addressParent === 'user') {
                        commit('SET_USER', result);
                    }
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    setHasClientSwitch({commit}, hasClientSwitch) {
        commit('SET_HAS_CLIENT_SWITCH', hasClientSwitch);
    },
    getShopClients(ctx, {limit, offset, filterCriteria}) {
        return new Promise((resolve, reject) => {
            userApi.getShopClients(
                limit,
                offset,
                filterCriteria.searchText,
                filterCriteria.ignoreShopClientId,
                filterCriteria.shopClientGroupId
            ).then((result) => {
                resolve(result);
            }).catch((error) => {
                reject(error);
            });
        });
    },
    switchShopClient(ctx, newShopClientId) {
        return new Promise((resolve, reject) => {
            userApi.switchShopClient(
                newShopClientId
            ).then(() => {
                this.dispatch('session/refreshState').then(() => resolve());
            }).catch((error) => {
                reject(error);
            });
        });
    },
    setAdminUrl({commit}, adminUrl) {
        commit('SET_ADMIN_URL', adminUrl);
    },
    logDataCheckAction() {
        return new Promise((resolve, reject) => {
            userApi.logDataCheckAction()
                .then(() => {
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
        });
    },
    addSubstitute: function (ctx, data) {
        return new Promise((resolve, reject) => {
            userApi.addSubstitute(data.userId, data.substituteId)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    removeSubstitute: function (ctx, data) {
        return new Promise((resolve, reject) => {
            userApi.removeSubstitute(data.userId, data.substituteId)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    getAllClientUsers: function (ctx, {filterCriteria, limit, offset}) {
        const orderBy = {};

        return new Promise((resolve, reject) => {
            userApi.getAllClientUsers(filterCriteria, orderBy, limit, offset)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

// mutations
const mutations = {
    RESET(state) {
        state.user = null;
        state.shopClient = null;
        state.client = null;
        state.hasClientSwitch = false;
        state.adminUrl = null;
    },
    SET_USER: (state, user) => {
        state.user = user;
    },
    SET_SHOP_CLIENT: (state, shopClient) => {
        state.shopClient = shopClient;
    },
    SET_CLIENT: (state, client) => {
        state.client = client;
    },
    SET_HAS_CLIENT_SWITCH: (state, hasClientSwitch) => {
        state.hasClientSwitch = hasClientSwitch;
    },
    SET_ADMIN_URL: (state, adminUrl) => {
        state.adminUrl = adminUrl;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
