<template>
    <div
        v-window-resize="testForAspectRatio"
        class="card"
        :class="{['variant-' + variant]: true}"
        @click="$emit('card-click')"
    >
        <div class="preview-wrapper d-flex align-items-center justify-content-center flex-grow-0 flex-shrink-0">
            <div
                v-if="entry.type === 'image'"
                class="imagePreview"
            >
                <NdxImage
                    :src="entry.filename"
                    :alt="entry.name"
                    size="xxl"
                />
            </div>
            <div
                v-else-if="entry.type === 'color'"
                class="colorPreview"
                :style="colorStyle"
            ></div>
            <div
                v-else-if="entry.type === 'font'"
                class="fontPreview"
            >
                <div v-html="fontDef"></div>
                <span :style="fontStyle">AaZz</span>
            </div>
            <div
                v-else-if="entry.type === 'htmlFragment'"
                class="htmlFragmentPreview"
            >
                <div class="overlay"></div>
                <NdxIframe scrolling="no">
                    <div v-html="entry.value"></div>
                </NdxIframe>
            </div>
            <div
                v-else-if="entry.type === 'file' &&
                    ['pdf', 'png', 'jpg', 'jpeg', 'tif', 'tiff', 'svg', 'bmp'].includes(
                        entry.filename.substr(entry.filename.lastIndexOf('.') + 1)
                    )"
                class="filePreview"
            >
                <NdxImage
                    :src="entry.filename"
                    :alt="entry.name"
                    size="xxl"
                />
            </div>
            <div
                v-else-if="entry.type === 'file'"
                class="filePreview"
            >
                <NdxIcon :icon="fileIcon" size="xl"/>
            </div>
        </div>
        <div class="card-body">
            <div class="card-title">{{ entry.name }}</div>
        </div>
        <div class="actions">
            <div
                v-for="(action, idx) of actions"
                :key="idx"
                class="card-action"
                :title="action.title"
                @click.stop="action.action"
            >
                <NdxIcon v-if="variant === 'card'" :icon="action.icon"/>
                <span v-if="['list', 'list-narrow'].includes(variant)">{{ action.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { stripHtmlTags } from "@utilities/ndxText";
    import NdxIframe from "./NdxIframe";
    import NdxIcon from "./NdxIcon";
    import NdxImage from "./NdxImage";
    import { mapGetters } from "vuex";

    export default {
        name: 'NdxAssetEntryCard',
        components: {NdxImage, NdxIcon, NdxIframe},
        props: {
            entry: {
                type: Object
            },
            /**
             * @values card, list, list-narrow
             */
            variant: {
                type: String,
                default: 'card',
                validator: function (value) {
                    return ['card', 'list', 'list-narrow'].includes(value);
                }
            }
        },
        computed: {
            ...mapGetters('assets', [
                'getAssetIcon'
            ]),
            fileIcon() {
                return this.getAssetIcon(this.entry);
            },
            colorStyle() {
                return this.entry ? 'background-color: ' + this.entry.value + ';' : '';
            },
            actions() {
                let actions = [];
                const hasDownload = ['font', 'image', 'file'];

                if (hasDownload.indexOf(this.entry.type) > -1) {
                    actions.push({
                        title: this.$t('label.download'),
                        icon: 'export',
                        action: this.downloadFile
                    });
                }

                return actions;
            },
            fontDef() {
                if (this.entry.type === 'font') {
                    return `
                <style>
                    @font-face {
                    font-family: "${this.entry.name}";
                    font-style: normal;
                    font-weight: 400;
                    src: url("/file/download/${this.entry.filename}") format("woff2");
                }
                </style>`;
                }

                return '';
            },
            fontStyle: function () {
                return 'font-family: "' + this.entry.name + '"';
            },

        },
        watch: {
            variant() {
                this.testForAspectRatio();
            }
        },
        mounted() {
            this.testForAspectRatio();
        },
        methods: {
            stripHtmlTags,
            downloadFile() {
                const categoryId = this.$route.params.categoryId || null;
                if (this.entry && 'filename' in this.entry && this.entry.filename.length) {
                    const downloadUrl = '/portal/file/assetDownload/' +
                        categoryId + '/' + this.entry.id + '/' + this.entry.filename;
                    window.open(downloadUrl, '_blank');
                }
            },
            testForAspectRatio() {
                if (this.variant === 'card' && !('aspectRatio' in this.$el.style)) {
                    this.$nextTick(
                        () => this.$el.querySelector('.preview-wrapper').style.height = this.$el.clientWidth + 'px'
                    );
                } else if (['list', 'list-narrow'].includes(this.variant) && !('aspectRatio' in this.$el.style)) {
                    this.$el.querySelector('.preview-wrapper').style.height = 'auto';
                }
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_bootstrap";
    @import "../../style/variables_ndx";
    @import "~bootstrap/scss/mixins/breakpoints";

    .card {
        width: 100%;
        position: relative;
        border-width: 0;
        cursor: pointer;

        .preview-wrapper {
            width: 100%;
            aspect-ratio: 1;
            background-color: var(--bs-gray-extra-light);
            border: 2px solid transparent;
            overflow: hidden;

            .fontPreview {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 4rem;
            }

            .imagePreview {
                width: 100%;
                height: 100%;

                :deep(img) {
                    object-fit: scale-down;
                    width: 100%;
                    height: 100%;
                }
            }

            .colorPreview {
                width: 100%;
                height: 100%;
            }

            .htmlFragmentPreview {
                position: relative;
                width: 100%;
                height: 100%;

                .overlay {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 1;
                }

                :deep(iframe) {
                    overflow: hidden;
                    height: 100%;
                }
            }
        }

        &.active .preview-wrapper,
        &:hover .preview-wrapper {
            border-color: #fff;
            border: 2px solid var(--bs-primary);
        }

        .card-title {
            @extend %font-h3;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .actions {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 32px;

            .card-action {
                width: 32px;
                height: 32px;
                background-color: var(--bs-white);
                border: 2px solid var(--bs-gray-middle);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                & + .card-action {
                    margin-top: 12px;
                }
            }
        }
    }

    .card.variant-card {
        .actions {
            .card-action {
                &:hover, &:active {
                    border-color: var(--bs-primary);
                    background-color: var(--bs-primary-light);
                }
            }
        }
    }

    .card.variant-list {
        $cardWidth: 80px;

        display: grid;
        grid-template-columns: $cardWidth 2fr;
        gap: 16px;
        width: 100%;
        aspect-ratio: unset;
        position: relative;

        .preview-wrapper {
            height: $cardWidth;
            width: $cardWidth;
            background-color: var(--bs-gray-super-light);
        }

        .card-body {
            padding-top: 0;
            padding-bottom: 0;
            height: $cardWidth;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            text-align: start;

            .card-title {
                white-space: normal;
                overflow: hidden;
                overflow-wrap: anywhere;
            }

            .card-text {
                display: none;
            }
        }

        .actions {
            position: initial;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            width: auto;
            white-space: nowrap;
            flex-wrap: wrap;
            gap: 16px;
            grid-column-start: 1;
            grid-column-end: 3;

            .card-action {
                width: auto;
                border: none;
                border-radius: initial;
                color: var(--bs-primary);
            }
        }
    }

    .card.variant-list-narrow {
        $cardWidth: 50px;

        display: grid;
        grid-template-columns: $cardWidth 2fr;
        gap: 8px;
        width: 100%;
        aspect-ratio: unset;
        position: relative;

        .preview-wrapper {
            height: $cardWidth;
            width: $cardWidth;
            background-color: var(--bs-gray-super-light);
        }

        .card-body {
            padding-top: 0;
            padding-bottom: 0;
            height: $cardWidth;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            text-align: start;

            .card-title {
                white-space: normal;
                overflow: hidden;
                overflow-wrap: anywhere;
            }

            .card-text {
                display: none;
            }
        }

        .actions {
            position: initial;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            width: auto;
            white-space: nowrap;
            flex-wrap: wrap;
            gap: 16px;
            grid-column-start: 1;
            grid-column-end: 3;

            .card-action {
                width: auto;
                border: none;
                border-radius: initial;
                color: var(--bs-primary);
            }
        }
    }

    @include media-breakpoint-up(md) {
        .card.variant-list {
            $cardWidth: 176px;

            grid-template-columns: $cardWidth 2fr;

            .preview-wrapper {
                height: $cardWidth;
                width: $cardWidth;
                background-color: var(--bs-gray-super-light);

            }

            .card-body {
                padding-top: 0;
                padding-bottom: 0;
                height: $cardWidth;

                .card-text {
                    display: block;
                    max-height: 135px;
                    overflow: hidden;
                    overflow-wrap: anywhere;
                }
            }
        }
    }

    /* fix browsers on iOS that do not support the gap property */
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        .card.variant-card:not(:last-child) {
            margin-right: 16px;
        }
        .card.variant-list:not(:last-child) {
            margin-bottom: 16px;
        }
    }
</style>
