<template>
    <GroupItem
        :item="item"
        in-tree
        :bundle-path="bundlePath"
        :is-multi-address="isMultiAddressDefinition"
        :multi-address-info="multiAddressInfo"
        :multi-address-info-errors="multiAddressInfoErrors"
    >
        <template #user-actions v-if="isMultiAddressDefinition">
            <div class="mw-100 d-flex gap-3 flex-column">
                <NdxNotification
                    variant="error"
                    :model-value="multiAddressInfoErrors.length > 0"
                    :duration="0"
                    :message="multiAddressInfoErrors.join('<br/>')"
                    class="mb-2"
                />
                <div
                    v-for="(addressInfo, idx) in multiAddressInfo"
                    :key="addressInfo.addressId"
                    class="roundedBorder d-flex flex-wrap gap-3 p-3"
                >
                    <NdxNotification
                        v-if="hasNoDeliveryOption(item.orderItemId, addressInfo.addressId)"
                        variant="error"
                        :model-value="true"
                        :duration="0"
                        :message="$t('message.basketItem_not_deliverable')"
                        class="w-100"
                    />
                    <NdxNotification
                        v-if="getInvalidAddresses.includes(addressInfo.addressId)"
                        variant="error"
                        :model-value="true"
                        :duration="0"
                        :message="$t('message.invalidAddressError')"
                        class="w-100"
                    />
                    <div class="flex-grow-0 multiAddressInput p-0">
                        <NdxInput
                            variant="secondary"
                            :model-value="parseFloat(addressInfo.quantity)"
                            inputmode="numeric"
                            @update:model-value="(quantity) => setNewSubQuantity(idx, quantity)"
                        />
                    </div>
                    <div class="flex-grow-1 d-flex align-items-center gap-3 mw-100 p-0">
                        <div class="flex-grow-1 mw-100">
                            <NdxSelect
                                variant="secondary"
                                :options="addressOptions"
                                text-wrap
                                :model-value="addressInfo.addressId"
                                @update:model-value="(newAddressId) => setNewAddress(idx, newAddressId)"
                            />
                        </div>
                        <div class="flex-grow-0 pointer">
                            <NdxIcon
                                icon="remove"
                                @click="() => removeMultiAddressEntry(idx)"
                                :title="$t('btn.removeDeliveryAddress')"
                            />
                        </div>
                    </div>
                </div>
                <div class="secondLvl">
                    <NdxButtonLink @click="addMultiAddressEntry">
                        {{ $t('btn.addMultiAddressEntry1') }}
                    </NdxButtonLink>
                    {{ $t('btn.addMultiAddressEntry2') }}
                </div>
            </div>
        </template>
    </GroupItem>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import { AddressFormatter } from "../../../plugins/formatter";
    import GroupItem from "./parts/GroupItem";
    import NdxSelect from "../../library/formElements/NdxSelect";
    import NdxIcon from "../../library/NdxIcon";
    import NdxButtonLink from "../../library/NdxButtonLink";
    import NdxNotification from "../../library/NdxNotification";
    import NdxInput from "../../library/formElements/NdxInput";

    export default {
        name: "AddressesGroupItem",
        components: {NdxInput, NdxNotification, NdxButtonLink, NdxIcon, NdxSelect, GroupItem},
        props: {
            item: {
                type: Object,
                required: true
            },
            isMultiAddress: {
                type: Boolean
            }
        },
        emits: ['update-valid'],
        data() {
            return {
                multiAddressInfo: []
            };
        },
        computed: {
            ...mapState({
                shopAdditionalPriceText: state => state.shop.additionalPriceText,
                multiAddressAddresses: state => state.basket.multiAddressAddresses ?? {}
            }),
            ...mapGetters('user', [
                'clientAddresses',
                'getAddress'
            ]),
            ...mapGetters('shop', {
                currency: 'currency',
                defaultProductImageBgColor: 'getDefaultProductImageBgColor'
            }),
            ...mapGetters('basket', [
                'isMultiAddressDelivery',
                'getMultiAddressForItem',
                'getDefaultDeliveryAddress',
                'getInvalidAddresses'
            ]),
            bundlePath() {
                return this.$store.getters['bundles/getPathFromOrderItemId'](this.item.orderItemId);
            },
            isMultiAddressDefinition() {
                return this.isMultiAddress || this.isMultiAddressDelivery;
            },
            multiAddress() {
                let addressInfo = JSON.parse(JSON.stringify(
                    this.getMultiAddressForItem(this.item.orderItemId)?.addresses ?? {}
                ));

                for (const idx in addressInfo) {
                    addressInfo[idx].address = this.getAddress(addressInfo[idx].addressId);
                }

                return addressInfo;
            },
            addressOptions() {
                let addressOptions = [];

                const filter = function (addressList) {
                    return addressList.filter((address) => {
                        return address.addressTypes.find(item => item.addressType === 'delivery') !== undefined;
                    });
                };

                if (Object.keys(this.multiAddressAddresses).length) {
                    for (const scId in this.multiAddressAddresses) {
                        let clientAddresses = filter(Object.values(this.multiAddressAddresses[scId].addresses));
                        if (clientAddresses.length) {
                            addressOptions.push({
                                value: 'sc' + scId,
                                text: this.multiAddressAddresses[scId].clientName,
                                disabled: true
                            });
                            for (const idx in clientAddresses) {
                                const address = clientAddresses[idx];
                                addressOptions.push({
                                    value: +address.id,
                                    text: this.formatAddress(address),
                                    disabled: !this.$store.getters['basket/isUseableDeliveryAddress'](address)
                                });
                            }
                        }
                    }
                } else {
                    let list = filter(this.clientAddresses);
                    for (const idx in list) {
                        addressOptions.push({
                            value: +list[idx].id,
                            text: this.formatAddress(list[idx])
                        });
                    }
                }

                let addresses = filter(this.$store.getters['user/userAddresses']);
                if (addresses.length) {
                    addressOptions.push({
                        value: 'us' + this.$store.getters['user/currentUserId'],
                        text: this.$t('label.user'),
                        disabled: true
                    });
                    for (const idx in addresses) {
                        const address = addresses[idx];
                        addressOptions.push({
                            value: +address.id,
                            text: this.formatAddress(address),
                            disabled: !this.$store.getters['basket/isUseableDeliveryAddress'](address)
                        });
                    }
                }

                return addressOptions;
            },
            multiAddressInfoErrors() {
                let errors = [];
                const targetQuantity = parseFloat(this.item.quantity);
                const multiAddressQuantitySum = this.multiAddressInfo.reduce(
                    (sum, entry) => sum + parseFloat(entry.quantity), 0
                );
                const multiAddressIdReUsage = this.multiAddressInfo.filter(
                    (entry, index) => this.multiAddressInfo.findIndex(e => e.addressId === entry.addressId) !== index
                ).length > 0;

                const multiAddressIdMissing = this.multiAddressInfo.filter(
                    (entry) => !entry.addressId
                ).length > 0;

                if (targetQuantity !== multiAddressQuantitySum) {
                    errors.push(this.$t('message.multiAddressQuantityError'));
                }

                if (multiAddressIdReUsage) {
                    errors.push(this.$t('message.multiAddressAddressesNotUnique'));
                }

                if (multiAddressIdMissing) {
                    errors.push(this.$t('message.multiAddressIdMissing'));
                }

                return errors;
            },
            isValid() {
                return this.isMultiAddressDefinition === false || this.multiAddressInfoErrors.length === 0;
            }
        },
        watch: {
            isMultiAddress: function (boolean) {
                if (boolean && !this.isMultiAddressDelivery) {
                    this.multiAddressInfo = [{
                        addressId: this.getDefaultDeliveryAddress?.id,
                        quantity: parseFloat(this.item.quantity)
                    }];
                    this.saveMultiAddress();
                }
            },
            isValid: function (newVal) {
                this.$emit('update-valid', {
                    orderItemId: this.item.orderItemId,
                    valid: newVal
                });
            }
        },
        created() {
            if (this.isMultiAddressDelivery) {
                for (const idx in this.multiAddress) {
                    this.multiAddressInfo.push({
                        addressId: this.multiAddress[idx].addressId,
                        quantity: this.multiAddress[idx].quantity
                    });
                }
            }
        },
        methods: {
            formatAddress(addressArr) {
                return AddressFormatter(addressArr, this, ', ');
            },
            setNewSubQuantity(idx, quantity) {
                if (parseFloat(quantity) == quantity && parseFloat(quantity) > 0) {
                    this.multiAddressInfo[idx].quantity = quantity;
                    this.saveMultiAddress();
                }
            },
            setNewAddress(idx, newAddressId) {
                this.multiAddressInfo[idx].addressId = newAddressId;
                this.saveMultiAddress();
            },
            removeMultiAddressEntry(index) {
                this.multiAddressInfo.splice(index, 1);
                this.saveMultiAddress();
            },
            addMultiAddressEntry() {
                const firstSelectableEntryIdx = this.addressOptions.findIndex((entry) => !entry?.disabled);
                this.multiAddressInfo.push({
                    addressId: this.addressOptions[firstSelectableEntryIdx].value,
                    quantity: 1
                });
                this.saveMultiAddress();
            },
            saveMultiAddress() {
                if (this.isValid) {
                    const data = {};
                    data[this.item.orderItemId] = this.multiAddressInfo;

                    this.$store.dispatch('basket/saveMultiAddress', data);
                }
            },
            hasNoDeliveryOption(orderItemId, addressId) {
                const orderItem = this.getMultiAddressForItem(orderItemId);
                if (orderItem && orderItem.addresses) {
                    const address = orderItem.addresses.find(address => address.addressId === addressId);
                    return address && address.deliveryOptions.length === 0;
                }
                return false;
            }
        }
    };
</script>
