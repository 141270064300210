<template>
    <GroupItem
        :item="item"
        in-tree
        :bundle-path="bundlePath"
        hide-budget-info
    >
        <template #user-actions>
            <template v-if="item.availableBudgets.length > 0">
                <div class="user-actions-headline">{{ $t('label.budget')}}</div>

                <BudgetSelect
                    :costs="budgetRelevantCosts"
                    :available-budgets="item.availableBudgets"
                    :selected-budget="item.budget ? item.budget.id : null"
                    @change-budget="setNewBudget"
                />
            </template>

            <div
                v-if="!hideDeliveryHeadline"
                class="user-actions-headline"
            >{{ $t('label.product_deliveryOptions')}}</div>

            <div
                v-if="isMultiAddressDelivery"
                class="d-flex flex-column gap-3"
            >
                <div
                    v-for="addressItem in multiAddress"
                    :key="addressItem.addressId"
                >
                    <div class="d-flex gap-3">
                        <div class="key">{{ $t('label.quantity') }}: </div>
                        <div class="value">{{ $n(+addressItem.quantity) }}</div>
                    </div>
                    <div class="d-flex gap-3">
                        <div class="key">{{ $t('label.deliveryAddress') }}: </div>
                        <div class="value">{{ formatAddress(addressItem.address) }}</div>
                    </div>
                    <div class="pt-3" v-if="!hideDelivery(item, addressItem)">
                        <NdxNotification
                            variant="error"
                            :model-value="addressItem.deliveryOptions.length === 0"
                            :duration="0"
                            :message="$t('message.noDeliveryOptionAvailable')"
                            class="mb-2"
                        />
                        <div v-if="addressItem.deliveryOptions.length > 0">
                            <NdxNotification
                                variant="error"
                                :model-value="!deliverySelectOptions(addressItem.deliveryOptions)
                                    .find((entry) => entry.value === addressItem.selectedOptionId)"
                                :duration="0"
                                :message="$t('message.deliveryOptionNoLongerValid')"
                                class="mb-2"
                            />
                            <NdxSelect
                                variant="secondary"
                                text-wrap
                                :options="deliverySelectOptions(addressItem.deliveryOptions, addressItem.address)"
                                :model-value="addressItem.selectedOptionId"
                                @update:model-value="
                                    (optionId) => updateAddress(optionId, addressItem.orderItemAddressId)"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div v-else-if="!hideDelivery(item, null)">
                <NdxNotification
                    variant="error"
                    :model-value="orderItemDeliveryOptions.length === 0"
                    :duration="0"
                    :message="$t('message.noDeliveryOptionAvailable')"
                    class="mb-2"
                />
                <div v-if="orderItemDeliveryOptions.length > 0">
                    <NdxNotification
                        variant="error"
                        :model-value="!deliverySelectOptions(orderItemDeliveryOptions)
                            .find(entry => entry.value === checkoutData.orderItems[item.orderItemId].selectedOptionId)"
                        :duration="0"
                        :message="$t('message.deliveryOptionNoLongerValid')"
                        class="mb-2"
                    />
                    <NdxSelect
                        variant="secondary"
                        text-wrap
                        :options="deliverySelectOptions(orderItemDeliveryOptions, checkoutData.deliveryAddress)"
                        :model-value="checkoutData.orderItems[item.orderItemId].selectedOptionId"
                        @update:model-value="(optionId) => updateOrderItem(optionId)"
                    />
                </div>
            </div>
        </template>
    </GroupItem>
</template>

<script>
    import { mapGetters, mapState } from "vuex";
    import GroupItem from "./parts/GroupItem";
    import { AddressFormatter, PriceFormatter } from "../../../plugins/formatter";
    import NdxSelect from "../../library/formElements/NdxSelect";
    import NdxNotification from "../../library/NdxNotification";
    import BudgetSelect from "../../basket/parts/BudgetSelect";

    export default {
        name: "ShippingGroupItem",
        components: {BudgetSelect, NdxNotification, NdxSelect, GroupItem},
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        emits: ['group-item-updated'],
        computed: {
            ...mapState({
                checkoutData: state => state.basket.checkoutData
            }),
            ...mapGetters('basket', [
                'isMultiAddressDelivery',
                'getMultiAddressForItem'
            ]),
            ...mapGetters('user', [
                'getAddress'
            ]),
            ...mapGetters('shop', {
                currency: 'currency'
            }),
            bundlePath() {
                return this.$store.getters['bundles/getPathFromOrderItemId'](this.item.orderItemId);
            },
            multiAddress() {
                let addressInfo = JSON.parse(JSON.stringify(
                    this.getMultiAddressForItem(this.item.orderItemId)?.addresses || {}
                ));
                for (const idx in addressInfo) {
                    addressInfo[idx].address = this.getAddress(addressInfo[idx].addressId);
                }
                return addressInfo;
            },
            orderItemDeliveryOptions() {
                return this.checkoutData.orderItems[this.item.orderItemId].deliveryOptions;
            },
            budgetRelevantCosts() {
                let costs = parseFloat(this.item.priceNet);
                costs += parseFloat(this.item.deliveryCosts);
                if (this.item.additionalPriceNet) {
                    costs += parseFloat(this.item.additionalPriceNet);
                }
                return costs;
            },
            hideDeliveryHeadline() {
                return this.item.hideDelivery && (
                    (!this.isMultiAddressDelivery && this.hideDelivery(this.item, null)) ||
                    (this.isMultiAddressDelivery && this.multiAddress.reduce((hide, addressItem) =>
                        hide && this.hideDelivery(this.item, addressItem), true)
                    )
                );
            }
        },
        methods: {
            formatAddress(addressArr) {
                return AddressFormatter(addressArr, this, ', ');
            },
            getCorrectScale(deliveryOption, countryId) {
                if (countryId) {
                    // get the scale with the matching region
                    for (let scale of deliveryOption.scales) {
                        if (scale.regions.indexOf(countryId) > -1) {
                            return scale;
                        }
                    }
                    // get the scale with the "world" region
                    for (let scale of deliveryOption.scales) {
                        if (scale.regions.length === 0) {
                            return scale;
                        }
                    }
                }

                return deliveryOption.scales[0];
            },
            hideDelivery(orderItem, addressItem) {
                return orderItem.hideDelivery &&
                    ((this.isMultiAddressDelivery && addressItem.deliveryOptions.length <= 1) ||
                    (!this.isMultiAddressDelivery && this.orderItemDeliveryOptions.length <= 1));
            },
            deliverySelectOptions(deliveryOptions, address) {
                let options = [];

                for (let idx in deliveryOptions) {
                    let infoText = [];

                    if (!this.item.hideDelivery) {
                        const scale = this.getCorrectScale(
                            deliveryOptions[idx],
                            address ? address.countryId : null
                        );

                        if (scale.deliveryTimeTag !== null) {
                            if (scale.deliveryTimeTag.length) {
                                infoText.push(scale.deliveryTimeTag);
                            }
                        } else if (scale.min > 0 || scale.max > 0) {
                            if (scale.min === scale.max) {
                                infoText.push(scale.min + ' ' + this.$tc('units.workingday', scale.min));
                            } else {
                                infoText.push(
                                    scale.min + ' - ' + scale.max + ' ' + this.$tc('units.workingday', scale.max)
                                );
                            }
                        }

                        if (scale.freeShippingValue !== null) {
                            infoText.push(this.$t('label.freeShippingValue', {
                                value: PriceFormatter(scale.freeShippingValue, this.currency, this)
                            }));
                        }
                    }

                    const info = infoText.length > 0 ? ' (' + infoText.join(', ') + ')' : '';
                    options.push({
                        value: deliveryOptions[idx].id,
                        text: deliveryOptions[idx].name + info
                    });
                }

                return options;
            },
            updateOrderItem(optionId) {
                this.$store.dispatch('basket/updateDeliveryOptionOrderItem', {
                    orderItemId: this.item.orderItemId,
                    optionId: optionId
                }).finally(() => {
                    this.$emit('group-item-updated');
                });
            },
            updateAddress(optionId, orderItemAddressId) {
                this.$store.dispatch('basket/updateDeliveryOptionAddressItem', {
                    orderItemAddressId: orderItemAddressId,
                    optionId: optionId
                }).finally(() => {
                    this.$emit('group-item-updated');
                });
            },

            setNewBudget(budgetId) {
                this.$store.dispatch('basket/updateBudgetUsed', {
                    basketItemId: this.item.orderItemId,
                    budgetId: budgetId
                }).finally(() => {
                    this.$emit('group-item-updated');
                });
            }
        }
    };
</script>
