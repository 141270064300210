/**
 * @param {Date|Object|Number|String|Function|null} date
 *
 * @returns {Date|Function|null}
 */
export function ndxDateConvert(date) {
    if (date === null || date === undefined || date instanceof Date ||
        (typeof (date) === 'object' && date.constructor.toString().match(/date/i))
    ) {
        return date;
    }
    if (typeof (date) === 'number') {
        return new Date(date);
    }
    if (typeof (date) === 'function') {
        return date;
    }
    if (date.date) {
        date = date.date;
    }
    const dateFormat =
        /(\d{4})-(\d{2})-(\d{2})(\s|T)?(\d{2}):(\d{2}):(\d{2})\.?.*/;
    const dateComponents = dateFormat.exec(date);

    if (!dateComponents) {
        return null;
    }

    let retDate = new Date();

    // Initialize day with 1 so this works on 31.01.2012
    retDate.setUTCDate(1);

    retDate.setUTCFullYear(dateComponents[1]);
    // JavaScript-Date's Month begins with 0=January
    retDate.setUTCMonth(dateComponents[2] - 1);
    retDate.setUTCDate(dateComponents[3]);
    retDate.setUTCHours(dateComponents[5]);
    retDate.setUTCMinutes(dateComponents[6]);
    retDate.setUTCSeconds(dateComponents[7]);

    return retDate;
}

/**
 * @param {Date} date
 * @param {String} separator
 *
 * @returns {string}
 */
export function ndxDateFormatYmd(date, separator = '-') {
    let parts = [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
    ];
    return parts.join(separator);
}

/**
 * e.g.: 2013-01-23T14:42:45.546+0200.
 *
 * @param {Date} date
 * @param {Number} daysToAdd
 *
 * @returns {string}
 */
export function camundaFormat(date, daysToAdd = 0) {
    const newDate = addDays(date, daysToAdd);

    // returns utc, so the timezone diff is always +0
    return newDate.toISOString().replace('Z', '+0000');
}

/**
 * @param {Date} date
 * @param {Number} daysToAdd
 *
 * @returns {Date}
 */
export function addDays(date, daysToAdd = 0) {
    const newDate = new Date(date);

    if (+daysToAdd > 0) {
        newDate.setDate(newDate.getDate() + daysToAdd);
    }

    return newDate;
}

export function formatDuration(seconds, ctx, format = 'short') {
    const _format = format === 'short' ? 'short.' : '';
    if (+seconds <= 0) {
        let tmp = '0';
        if (format !== 'short') {
            tmp += " ";
        }
        tmp += ctx.$tc('unit.' + _format + 'second', 0);
        return tmp;
    }

    let years, days, hours, minutes;

    const YEAR_IN_SECONDS = 365 * 24 * 60 * 60;
    const DAY_IN_SECONDS = 24 * 60 * 60;
    const HOUR_IN_SECONDS = 60 * 60;
    const MINUTE_IN_SECONDS = 60;

    years = Math.floor(seconds / YEAR_IN_SECONDS);

    seconds -= years * YEAR_IN_SECONDS;
    days = Math.floor(seconds / DAY_IN_SECONDS);

    seconds -= days * DAY_IN_SECONDS;
    hours = Math.floor(seconds / HOUR_IN_SECONDS);

    seconds -= hours * HOUR_IN_SECONDS;
    minutes = Math.floor(seconds / MINUTE_IN_SECONDS);

    seconds -= minutes * MINUTE_IN_SECONDS;

    const time = {
        year: years,
        day: days,
        hour: hours,
        minute: minutes,
        second: seconds
    };
    const timeArr = [];
    let timeStr = '';

    for (let unit in time) {
        if (time[unit] !== undefined && time[unit] !== 0) {
            let tmp = time[unit];
            if (format !== 'short') {
                tmp += " ";
            }
            tmp += ctx.$tc('unit.' + _format + unit, time[unit]);
            timeArr.push(tmp);
        }
    }

    timeArr.forEach(function (element, index, list) {
        if (list.length === 1) {
            timeStr = element;
            return timeStr;
        } else if (index === list.length - 2) {
            timeStr += element;
        } else if (index === list.length - 1) {
            let tmp = [];
            if (format !== 'short') {
                tmp.push(ctx.$t('label.and'));
            }
            tmp.push(element);

            timeStr += " " + tmp.join(' ');
        } else {
            timeStr += element;
            if (format !== 'short') {
                timeStr += ", ";
            } else {
                timeStr += " ";
            }
        }
    });

    return timeStr;
}

export function getDateDiffInSec(startDate, endDate) {
    const _startDate = !startDate ? new Date() : ndxDateConvert(startDate);
    const _endDate = !endDate ? new Date() : ndxDateConvert(endDate);

    const dif = _endDate.getTime() - _startDate.getTime();
    const Seconds_from_T1_to_T2 = Math.floor(dif / 1000);
    return Math.abs(Seconds_from_T1_to_T2);
}

export function ndxDateSub(diff, date = new Date())
{
    if (!(date instanceof Date)) {
        throw new Error ("Parameter date have to be of type Date");
    }

    let diffedDate = date;

    const diffParts = diff.match(/^(\d+)([YMD])$/);

    if (diffParts.length !== 3) {
        throw new Error ("Parameter diff has to be a number followed by one of Y, M or D");
    }

    const timeRange = diffParts[1];
    const timeUnit = diffParts[2];

    switch (timeUnit) {
        case 'Y':
            diffedDate.setFullYear(date.getFullYear() - timeRange);
            break;
        case 'M':
            diffedDate.setMonth(date.getMonth() - timeRange);
            break;
        case 'D':
            diffedDate.setDate(date.getDate() - timeRange);
            break;
    }

    return diffedDate;
}
