<template>
    <div class="comment-items">
        <div class="comment-items-headline">{{ $t('label.comments') }}</div>

        <div v-if="comments.length === 0" class="no_comments py-2">
            {{ $t('message.noComments') }}
        </div>

        <div
            v-for="comment of comments"
            :key="comment.id"
            class="comment-item"
        >
            <div class="comment-date">{{ $d(comment.createdAt, 'long') }}</div>
            <div class="comment-message" v-html="formatComment(comment.message)"></div>
            <div class="comment_files" v-if="comment.files && comment.files.length > 0">
                <div class="comment_files-headline">{{ $t('label.files') }}</div>
                <div class="file-grid">
                    <template
                        v-for="file in comment.files"
                        :key="file.id"
                    >
                        <td class="action-data" @click="downloadFile(file)">
                            <div class="d-flex align-items-center gap-2">
                                <NdxIcon icon="export" size="s"/>
                                <span>{{ file.originalFilename }}</span>
                            </div>
                        </td>
                        <td class="size-data">{{ fileSize(file.size) }}</td>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import NdxIcon from "../../library/NdxIcon.vue";
    import { fileSize } from "@utilities/ndxFile";

    export default {
        name: 'Comments',
        components: {NdxIcon},
        props: {
            comments: {
                type: Array
            }
        },
        methods: {
            formatComment(comment) {
                return comment.replaceAll("\n", "<br/>");
            },
            fileSize(size) {
                return fileSize(size ?? 0).asString();
            },
            downloadFile(file) {
                window.open('/file/download/' + file.filename, '_blank');
            }
        }
    };
</script>

<style lang="scss" scoped>
    .file-grid {
        display: grid;
        grid-template-columns: max-content max-content;
        column-gap: 16px;
    }
</style>
