import lodashMerge from "lodash/merge";

//state
const state = {
    bundleTrees: [],
    breadcrumb: null,
    activeBundle: null
};

// getters
const getters = lodashMerge({}, {
    getPathFromOrderItemId(state) {
        function _isItemInPath(tree, orderItemId, path) {
            if (tree.bundleProduct && tree.children) {
                let found = tree.children.find((node) => {
                    if (node.bundleProduct) {
                        return _isItemInPath(node, orderItemId, path);
                    } else {
                        return node.orderItemId == orderItemId;
                    }
                });
                if (found) {
                    path.push(tree);
                    return true;
                }
            }
            return false;
        }

        return (orderItemId) => {
            for (const bundleTree of state.bundleTrees) {
                let path = [];
                if (_isItemInPath(bundleTree, orderItemId, path)) {
                    if (path.length) {
                        return path.reverse();
                    }
                }
            }
            return null;
        };
    },
    getPathFromBundleId(state) {
        function _buildPath(node, bundleId, path) {

            if (node.children) {
                let found = false;
                node.children.forEach((child) => {
                    if (_buildPath(child, bundleId, path)) {
                        found = true;
                    }
                });
                if (found) {
                    path.push(node);
                    return true;
                }
            }
            if (node.bundleId === bundleId) {
                path.push(node);
                return true;
            }
            return false;
        }

        return (bundleId) => {
            for (const bundleTree of state.bundleTrees) {
                let path = [];
                _buildPath(bundleTree, bundleId, path);
                if (path.length) {
                    return path.reverse();
                }
            }
            return null;
        };
    },

    getOrderItemIdsFromTree() {
        return (tree) => {
            let ids = [];
            let stack = JSON.parse(JSON.stringify(tree.children)) || [];

            if (tree.orderItemId) {
                ids.push(tree.orderItemId);
            }

            while (stack.length) {
                const entry = stack.shift();

                if (entry.orderItemId) {
                    ids.push(entry.orderItemId);
                }

                if (entry.children?.length) {
                    stack = stack.concat(entry.children);
                }
            }

            return ids;
        };
    },
});

// actions
const actions = {
    resetBundles({commit}) {
        commit('SET_BUNDLE_PATH', null);
        commit('SET_ACTIVE_BUNDLE', null);
    },
    setPath({commit}, path) {
        commit('SET_BUNDLE_PATH', path);
        if (path?.length) {
            commit('SET_ACTIVE_BUNDLE', path[path.length - 1]);
        } else {
            commit('SET_ACTIVE_BUNDLE', null);
        }
    },
    setBundleTrees({commit}, trees) {
        commit('SET_BUNDLE_TREES', trees);
    }
};

// mutations
const mutations = {
    RESET(state) {
        state.bundleTrees = [];
        state.breadcrumb = null;
        state.activeBundle = null;
    },
    SET_BUNDLE_TREES(state, trees) {
        state.bundleTrees = trees;
    },
    SET_BUNDLE_PATH(state, path) {
        state.breadcrumb = path;
    },
    SET_ACTIVE_BUNDLE(state, bundle) {
        state.activeBundle = bundle;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
