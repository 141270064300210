<template>
    <div class="w-100 d-flex flex-column gap-3">
        <div v-if="!hideActiveClient">
            <div class="title mb-2">{{ $t('label.currentClient')}}</div>
            <div class="activeClient">
                {{ currentClientLabel() }} <span class="label-id">#{{ currentShopClientId }}</span>
            </div>
        </div>
        <div class="title mt-4">
            <span :class="{breadcrumbRoot:!!activeGroup}" @click="selectShopClientsGroup(null)">
                {{ $t('label.clients')}}
            </span>
            <span v-if="activeGroup"> / {{activeGroup.name}} </span>

            <NdxButton
                class="back"
                v-if="activeGroup"
                variant="pill"
                @click="selectShopClientsGroup(null)"
            >Zurück</NdxButton>
        </div>

        <NdxSearchInput
            class="w-100"
            v-model="searchText"
            :removable="true"
            @on-key-enter="setFilterCriteria"
            @on-remove-value="searchText='';setFilterCriteria()"
        />

        <NdxListing
            store-action="user/getShopClients"
            :filterCriteria="filterCriteria"
            @result-changed="(result) => orgGroups = result.orgGroups"
            @items-changed="(list) => shopClients = list"
            :has-additional-content="orgGroups.length > 0"
        >
            <div class="d-flex gap-3 flex-column px-2">
                <NdxButton
                    v-for="shopClient in shopClients"
                    :key="shopClient.id"
                    @click="selectedClient(shopClient)"
                    variant="secondary"
                    :disabled="switching"
                >
                    {{ getLabel(shopClient) }} <span class="label-id">#{{ shopClient.id }}</span>
                </NdxButton>
                <template v-if="!activeGroup">
                    <div v-if="orgGroups.length" class="separator">
                        <div class="line"></div>
                        <div class="text">{{$t('label.moreShopClients')}}</div>
                    </div>
                    <template v-for="group in orgGroups" :key="group.id">
                        <div
                            class="orgGroup"
                            :class="{active: activeGroup === group}"
                            @click="selectShopClientsGroup(group)"
                        >
                            {{group.name}}
                        </div>
                    </template>
                </template>
            </div>
        </NdxListing>
    </div>
</template>

<script>
    import NdxButton from "./NdxButton.vue";
    import NdxSearchInput from "./formElements/NdxSearchInput.vue";
    import NdxListing from "./NdxListing.vue";
    import { mapState } from "vuex";
    import { getRouteAfterLogin } from "../../router";

    export default {
        name: 'NdxClientSelect',
        components: {NdxListing, NdxSearchInput, NdxButton},
        props: {
            hideActiveClient: {
                type: Boolean
            }
        },
        data() {
            return {
                switching: false,
                searchText: '',
                shopClients: [],
                orgGroups: [],
                activeGroup: null,

                filterCriteria: {
                    searchText: '',
                    ignoreShopClientId: null,
                    shopClientGroupId: null
                }
            };
        },
        computed: {
            ...mapState({
                currentShopClientId: state => state.user.shopClient?.id,
                currentClient: state => state.user.client
            })
        },
        created() {
            if (!this.hideActiveClient) {
                this.filterCriteria.ignoreShopClientId = this.currentShopClientId;
            }
        },
        methods: {
            selectShopClientsGroup(group) {
                if (!group) {
                    this.activeGroup = null;
                    this.filterCriteria.shopClientGroupId = null;
                    return;
                }

                this.activeGroup = group;
                this.filterCriteria.shopClientGroupId = group.id;
            },
            setFilterCriteria() {
                this.filterCriteria.searchText = this.searchText;
            },
            selectedClient(shopClient) {
                this.switching = true;

                this.$store.dispatch('session/updatePortalCustomization', shopClient.customizations);
                this.$store.dispatch('user/switchShopClient', shopClient.id).then(() => {
                    this.$router.push(getRouteAfterLogin());
                });
            },
            getLabel(shopClient) {
                let parts = [];

                if ('name' in shopClient && shopClient.name.length > 0) {
                    parts.push(shopClient.name);
                }

                if ('matchcode' in shopClient && shopClient.matchcode.length > 0) {
                    parts.push(shopClient.matchcode);
                }

                return parts.join(' | ');
            },
            currentClientLabel() {
                return this.getLabel({
                    id: this.currentShopClientId,
                    matchcode: this.currentClient.name,
                    name: this.currentClient.contact.name
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import "../../style/variables_ndx";

    .title {
        @extend %font-h3;
    }
    .label-id {
        @extend %font-caption;
        margin-left: 4px;
    }
    .activeClient {
        color: var(--bs-primary-foreground);
        background-color: var(--bs-primary);
        padding: 11px 14px;
        border-radius: 8px;
        text-align: center;
    }

    .activeClient,
    .btn-secondary:hover {
        .label-id {
            color: var(--bs-primary-foreground);
        }
    }
    .separator {
        position: relative;
        .line {
            height: 2px;
            background-color: var(--bs-primary);
            margin: 10px 0;
        }
        .text {
            position: absolute;
            top: -1px;
            left:41%;
            background-color: white;
            padding: 0 10px;
        }
    }
    .orgGroup {
        border: 1px solid var(--bs-primary);
        border-radius: 8px;
        text-align: center;
        cursor: pointer;
        padding:8px;
        &.active {
            background-color: var(--bs-primary);
            color: white;
        }
    }
    .breadcrumbRoot {
        cursor: pointer;
        &:hover {
            color: var(--bs-primary);
        }
    }
    .btn.back {
        margin-left: 8px;
        float: right;
        padding: 2px 10px;
    }
</style>
