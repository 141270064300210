<template>
    <div class="workflowlist-item">
        <div class="workflowlist-item-info">
            <div class="name">{{ item.name }}</div>
            <div class="keyValues">
                <div>
                    <span>{{ $t('label.workflow.instanceId') }}: </span>
                    {{ itemId }}
                </div>
                <div>
                    <span>{{ $t('label.requestedBy') }}: </span>
                    {{ item.businessObject.createdBy.username }}
                </div>
                <div v-if="'createdAt' in item">
                    <span>{{ $t('label.requestedAt') }}: </span>
                    {{ $d(dateConverter(item.createdAt), 'long') }}
                </div>
                <div v-if="'instanceCreatedAt' in item">
                    <span>{{ $t('label.requestedAt') }}: </span>
                    {{ $d(dateConverter(item.instanceCreatedAt), 'long') }}
                </div>
                <div v-if="'instanceEndedAt' in item">
                    <span>{{ $t('label.endedAt') }}: </span>
                    {{ $d(dateConverter(item.instanceEndedAt), 'long') }}
                </div>
            </div>
        </div>

        <BusinessObjectViewer
            class="separatorTop mt-3 pt-3"
            :business-object="item.businessObject"
            compact
        />

        <div class="d-flex align-items-end justify-content-end gap-3 pt-3">
            <NdxButtonLink
                class="ps-0"
                @click="showDetails"
            >{{ $t('btn.details') }}</NdxButtonLink>
        </div>
    </div>
</template>

<script>
    import { ndxDateConvert } from "@utilities/ndxDate";
    import BusinessObjectViewer from "./businessObject/BusinessObjectViewer.vue";
    import NdxButtonLink from "../library/NdxButtonLink.vue";

    export default {
        name: 'WorkflowInstanceItem',
        components: {NdxButtonLink, BusinessObjectViewer},
        props: {
            item: {
                type: Object
            }
        },
        data() {
            return {
                dateConverter: ndxDateConvert
            };
        },
        computed: {
            isSnapshot() {
                return 'isSnapshot' in this.item && this.item.isSnapshot === true;
            },
            itemId() {
                return this.isSnapshot ? this.item.extId : this.item.id;
            }
        },
        methods: {
            showDetails() {
                if (this.isSnapshot) {
                    this.$router.push({
                        name: 'WorkflowsInstanceCompletedDetail',
                        params: {
                            snapshotId: this.item.id
                        }
                    });
                } else {
                    this.$router.push({
                        name: 'WorkflowsInstanceDetail',
                        params: {
                            instanceId: this.item.id
                        }
                    });
                }
            }
        }
    };
</script>
^
